import { Controller } from "@hotwired/stimulus";

export default class ColorblindController extends Controller {
  static targets = ["checkbox", "element"];

  toggle() {
    this.elementTargets.forEach((elementTarget) => {
      elementTarget.classList.toggle("colorblind");
    });
  }
}
