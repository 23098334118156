
        import * as module0 from './controllers/colorblind_controller.js';import * as module1 from './controllers/favicon_controller.js';import * as module2 from './controllers/menu_controller.js';import * as module3 from './controllers/message_controller.js';import * as module4 from './controllers/theme_controller.js'
        const modules = {
            "./controllers/colorblind_controller.js": module0,
            "./controllers/favicon_controller.js": module1,
            "./controllers/menu_controller.js": module2,
            "./controllers/message_controller.js": module3,
            "./controllers/theme_controller.js": module4,
        };
        export default modules;
      