import { Controller } from "@hotwired/stimulus";

const DARK = "dark";
const LIGHT = "light";

export default class ThemeController extends Controller {
  static targets = ["switch", "body"];

  connect() {
    const mode = window.localStorage.getItem("theme");

    if (
      (!mode &&
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: light)").matches) ||
      mode === LIGHT
    ) {
      this.bodyTarget.dataset.theme = LIGHT;
    } else {
      this.switchTarget.checked = true;
    }
  }

  toggle() {
    this.bodyTarget.dataset.theme =
      this.bodyTarget.dataset.theme === LIGHT ? DARK : LIGHT;

    window.localStorage.setItem("theme", this.bodyTarget.dataset.theme);
  }
}
