import { Controller } from "@hotwired/stimulus";

export default class MenuController extends Controller {
  static targets = ["menu", "open", "close"];

  open() {
    this.show(this.menuTarget);

    this.show(this.closeTarget);
    this.hide(this.openTarget);
  }

  close() {
    this.hide(this.menuTarget);

    this.hide(this.closeTarget);
    this.show(this.openTarget);
  }

  show(element) {
    element.classList.remove("hidden");
    element.classList.add("flex");
  }

  hide(element) {
    element.classList.add("hidden");
    element.classList.remove("flex");
  }
}
