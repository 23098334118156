import { Controller } from "@hotwired/stimulus";

export default class MessageController extends Controller {
  static targets = ["display"];
  static values = { messages: [String] };

  connect() {
    this.changeMessage();
  }

  refresh() {
    this.changeMessage();
  }

  changeMessage() {
    const index =
      Math.floor(Math.random() * (this.messagesValue.length * 5)) %
      this.messagesValue.length;
    const message = this.messagesValue[index];

    this.displayTarget.innerHTML = message;
  }
}
