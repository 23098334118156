import { Controller } from "@hotwired/stimulus";

export default class FaviconController extends Controller {
  static targets = ["link"];

  connect() {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.linkTarget.href = "/favicon-dark.ico";
    }
  }
}
